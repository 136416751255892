import { I18n } from 'common/i18n';

export class MassCommunicationStatusValueConverter {
    static inject = [I18n];
    _i18n;
    constructor(i18n) {
        this._i18n = i18n;
    }

    toView(value, type = 'mailchimp') {
        if (!value || !type) return value;
        return this._i18n.tr(`${type}-status-${value}`);
    }
}
