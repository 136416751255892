import { Security } from 'common/security';
import moment from 'moment';
import { c } from 'common/common';

export class ReplaceVariablesValueConverter {
    static inject = [Security];
    _security;

    constructor(security) {
        this._security = security;
    }

    toView(value, skip = false, urlEncode = false) {
        if (skip) return value;
        if (!this._security.isAuthenticated || !(typeof value === 'string' || value instanceof String)) return value;
        const newValue = c.Helpers.replaceVariables(this._security, value, urlEncode);
        return newValue;
    }
}
