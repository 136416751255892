import moment from 'moment';

export class DobToAgeValueConverter { 
    toView(value, nearest = false) {
        if (!value) return '';
        try {
            const format = value.length === 10 ? 'MM/DD/YYYY' : undefined;
            const dob = moment(value, format);
            if (!dob.isValid()) return '';
            const years = moment().diff(dob, 'years', true);
            if (!nearest) {
                return Math.floor(years);
            }
            const floor = Math.floor(years);
            const decimal = years - floor;
            if (decimal >= 0.5) return floor + 1;
            return floor;
        } catch (err) {
            console.log(err);
            return value;
        }
    } 
}
