import { c } from 'common/common';

export class FormatNumber2ValueConverter {
    constructor() {}

    toView(value, decimals = 2, isMoney = false, isPercent = false, removeInsignificantDigits = false, displayPlusMinus = false, plusMinusZeroValue = '', isPercentDecimal = true) {
        const prefix = isMoney ? '$' : '';
        const suffix = isPercent ? '%' : '';
        if (value === undefined || value === null) return prefix;
        if (isMoney) removeInsignificantDigits = false;
        if (isPercent && isPercentDecimal) value = value * 100;
        let formattedValue = c.Helpers.formatNumber(value, decimals, removeInsignificantDigits);
        let plusMinus = '';
        if (displayPlusMinus) {
            if (value < 0) plusMinus = formattedValue.indexOf('-') !== 0 ? '-' : '';
            else if (value > 0) plusMinus = '+';
            else plusMinus = plusMinusZeroValue;
        }
        return `${plusMinus}${prefix}${formattedValue}${suffix}`;
    } 
}
