export class CodeToDescriptionValueConverter {
    toView(value, separateTo = false) {
        try {
            if (!value) return '';
            let desc = value.replace(/-/g, ' ');
            let words = desc.split(' ');
            let upperCasedDesc = '';
            words.forEach(w => {
                let doNotUpperCase = false;
                if (upperCasedDesc !== '') {
                    if (separateTo && w === 'to') {
                        doNotUpperCase = true;
                        upperCasedDesc += ':';
                    }
                    upperCasedDesc += ' ';
                }
                if (doNotUpperCase) {
                    upperCasedDesc += w;
                } else {
                    upperCasedDesc += `${w.charAt(0).toUpperCase()}${w.slice(1)}`;
                }
            });
            return upperCasedDesc;
        } catch (err) {
            return value;
        }
    }
}
