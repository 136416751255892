import {I18n} from 'common/i18n';

export class FormatNumberValueConverter {
    static inject = [I18n];

    constructor(i18n) {
        this.i18n = i18n;
    }

    toView(value, decimals = 2, isMoney = false, addPlusIfPositive = false) {
        const prefix = isMoney ? '$' : '';
        if (value === undefined || value === null) return prefix;
        if (!value) return `${prefix}${value}`;
        return this.i18n.formatNumber(value, decimals, prefix, addPlusIfPositive);
    } 
}
