import {I18n} from 'common/i18n';

export class BoolToYesNoValueConverter {
    static inject = [I18n];

    constructor(i18n) {
        this.i18n = i18n;
    }

	toView(value, yesKey = 'yes', noKey = 'no', nullKey = 'no') {
        if (value === null) return this.i18n.tr(nullKey);
	    return value ? this.i18n.tr(yesKey) : this.i18n.tr(noKey); 
	} 
}
