import { PLATFORM } from 'aurelia-pal';

const valueConverterGlobalResources = [
    PLATFORM.moduleName('resources/value-converters/level-name'),
    PLATFORM.moduleName('resources/value-converters/agent-number-status'),
    PLATFORM.moduleName('resources/value-converters/bool-to-yesno'),
    PLATFORM.moduleName('resources/value-converters/code-to-description'),
    PLATFORM.moduleName('resources/value-converters/contest-name'),
    PLATFORM.moduleName('resources/value-converters/coverage-type'),
    PLATFORM.moduleName('resources/value-converters/decode-js-property'),
    PLATFORM.moduleName('resources/value-converters/dob-to-age'),
    PLATFORM.moduleName('resources/value-converters/display-language'),
    PLATFORM.moduleName('resources/value-converters/email-parameter'),
    PLATFORM.moduleName('resources/value-converters/first-letter'),
    PLATFORM.moduleName('resources/value-converters/format-bytes'),
    PLATFORM.moduleName('resources/value-converters/format-date'),
    PLATFORM.moduleName('resources/value-converters/format-money'),
    PLATFORM.moduleName('resources/value-converters/format-number'),
    PLATFORM.moduleName('resources/value-converters/format-number2'),
    PLATFORM.moduleName('resources/value-converters/format-percent'),
    PLATFORM.moduleName('resources/value-converters/format-phone'),
    PLATFORM.moduleName('resources/value-converters/format-seconds'),
    PLATFORM.moduleName('resources/value-converters/format-time-period'),
    PLATFORM.moduleName('resources/value-converters/format-url'),
    PLATFORM.moduleName('resources/value-converters/gender'),
    PLATFORM.moduleName('resources/value-converters/line-of-business'),
    PLATFORM.moduleName('resources/value-converters/lead-disposition'),
    PLATFORM.moduleName('resources/value-converters/lead-status'),
    PLATFORM.moduleName('resources/value-converters/lead-type'),
    PLATFORM.moduleName('resources/value-converters/level-type'),
    PLATFORM.moduleName('resources/value-converters/mass-communication-status'),
    PLATFORM.moduleName('resources/value-converters/max-length'),
    PLATFORM.moduleName('resources/value-converters/member-inactive-reason'),
    PLATFORM.moduleName('resources/value-converters/note-record-type'),
    PLATFORM.moduleName('resources/value-converters/payment-method'),
    PLATFORM.moduleName('resources/value-converters/policy-rider-code'),
    PLATFORM.moduleName('resources/value-converters/policy-status'),
    PLATFORM.moduleName('resources/value-converters/replace-variables'),
    PLATFORM.moduleName('resources/value-converters/role-name'),
    PLATFORM.moduleName('resources/value-converters/shorten'),
    PLATFORM.moduleName('resources/value-converters/smoking-rating'),
    PLATFORM.moduleName('resources/value-converters/social-media-link'),
    PLATFORM.moduleName('resources/value-converters/task-type'),
    PLATFORM.moduleName('resources/value-converters/textarea-to-html'),
    PLATFORM.moduleName('resources/value-converters/time-between'),
    PLATFORM.moduleName('resources/value-converters/time-from-now'),
    PLATFORM.moduleName('resources/value-converters/title-case'),
    PLATFORM.moduleName('resources/value-converters/to-do-type'),
];

export default valueConverterGlobalResources;