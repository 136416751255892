import { I18n } from 'common/i18n';

export class PolicyRiderCodeValueConverter {
    static inject = [I18n];
    _i18n;
    constructor(i18n) {
        this._i18n = i18n;
    }

    toView(value, description, alternateDescription) {
        let alternateName = description || alternateDescription;
        if (!value) return alternateName;
        let riderCodeTr = this._i18n.tr(`policy:rider-code-${value}`);
        if (riderCodeTr.indexOf('rider-code-') === 0) return alternateName ?? value;
        return riderCodeTr;
    }
}
