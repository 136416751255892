export class FormatUrlValueConverter {
    toView(value) {
        try {
            if (!value) return '';
            if (value.indexOf('http') === 0) return value;
            return `https://${value}`;
        } catch (err) {
            console.log(err);
            return value;
        }
    }
}
