import { I18n } from 'common/i18n';

export class EmailParameterValueConverter {
    static inject = [I18n];
    _i18n;
    constructor(i18n) {
        this._i18n = i18n;
    }

    toView(value) {
        if (!value) return '';
        if (value.type === 'Email') return value.email;
        if (value.type === 'Agency' || value.type === 'Agent' || value.type === 'DirectUpline' || value.type === 'Mentor' || value.type === 'EntireUpline' || value.type === 'TaskAssignee' || value.type === 'UplineToAgency') return this._i18n.tr(`admin:email-type-${value.type.toLowerCase()}`);
        if (value.type === 'Role') return this._i18n.tr(`roles-name-${value.email}`);
        return value.email ?? value.type;
    }
}
