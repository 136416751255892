import { c } from 'common/common';

export class DecodeJsPropertyValueConverter {
    constructor() {}

	toView(value) {
        if (!value) return '';
	    return c.Helpers.decodeJsProperty(value);
	} 
}
