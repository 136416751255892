import { c } from 'common/common';

export class FormatMoneyValueConverter { 
    toView(value, decimals = 2) {
        try {
            if (value === null || value === undefined) return '';
            if (!value) return '$0';

            if (value < 0) return `($${c.Helpers.formatNumber(value * -1, decimals)})`;
            return `$${c.Helpers.formatNumber(value, decimals)}`;
        } catch (err) {
            console.log(err);
            return `$${value}`;
        }
    } 
}
