import { socialMedia } from 'common/common';

export class SocialMediaLinkValueConverter {
    constructor() {}

	toView(value, socialMediaKey) {
        if (!value || !socialMediaKey) return '';
        if (value.indexOf('http') === 0) return value;
        var linkStart = socialMedia.link(socialMediaKey);
        if (!linkStart) return value;
        return `${linkStart}${encodeURIComponent(value)}`;
	} 
}
