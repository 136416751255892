import {I18n} from 'common/i18n';

export class DisplayLanguageValueConverter {
    static inject = [I18n];

    constructor(i18n) {
        this.i18n = i18n;
    }

    toView(value) {
        return this.i18n.languageName(value);
    } 
}
