import { I18n } from 'common/i18n';

export class CoverageTypeValueConverter {
    static inject = [I18n];
    _i18n;
    constructor(i18n) {
        this._i18n = i18n;
    }

    toView(value) {
        if (!value) return value;
        let ct = this._i18n.tr(`coverage-type-${value}`);
        if (ct && ct != `coverage-type-${value}`) return ct;
        return value;
    }
}
