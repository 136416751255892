export class ShortenValueConverter {
    constructor() {}

    toView(value, shortenTo) {
        try {
            if (!value || !shortenTo) return value;
            if (value.length <= shortenTo) return value;
            return `${value.substr(0, shortenTo)}...`;
        } catch (err) {
            console.log(err);
            return value;
        }
    }
}
