import moment from 'moment';
import { CACHE_PERIOD } from 'services/production-service';
import { TIME_PERIOD } from 'common/constants';

export class FormatTimePeriodValueConverter {
    constructor() {}

	toView(value, period) {
        if (!value) return '';
        try {
            if (!value || !value.start || !value.end) return '';
            const start = moment(value.start);
            const end = moment(value.end);
            if (!start.isValid() || !end.isValid()) return '';
            switch (period) {
                case CACHE_PERIOD.Yesterday:
                case CACHE_PERIOD.LastYesterday:
                case TIME_PERIOD.Today:
                    return start.format('l');
                case CACHE_PERIOD.CurrentWeek:
                case CACHE_PERIOD.LastWeek:
                case CACHE_PERIOD.LastLastWeek:
                case TIME_PERIOD.ThisWeek:
                case TIME_PERIOD.WTD:
                    return `${start.format('l')} - ${end.format('l')}`;
                case CACHE_PERIOD.CurrentMonth:
                case CACHE_PERIOD.LastMonth:
                //case TIME_PERIOD.LastMonth:
                case TIME_PERIOD.ThisMonth:
                case TIME_PERIOD.MTD:
                case TIME_PERIOD.PriorMonth:
                    return end.format('MMM YYYY');
                case CACHE_PERIOD.CurrentYear:
                case CACHE_PERIOD.LastYear:
                case TIME_PERIOD.YTD:
                case TIME_PERIOD.PriorYear:
                    return end.format('YYYY');
            }
            return value;
        } catch (err) {
            console.log(err);
            return value;
        }
	} 
}
