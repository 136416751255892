import { c } from 'common/common';

export class FormatBytesValueConverter {
    toView(value) {
        try {
            if (!value) return '0 MB';
            let bytes = Number(value);
            if (isNaN(bytes)) return '-';
            if (bytes < 1000000) {
                let kb = c.Helpers.round(bytes / 1000, 2);
                return `${kb} KB`;
            }
            let mb = c.Helpers.round(bytes / 1000000, 2);
            return `${mb} MB`;
        } catch (err) {
            return value;
        }
    }
}
