import moment from 'moment';

export class FormatDateValueConverter { 
    toView(value, format, ignoreTz = false) {
        if (!value) return '';
        const parseExact = value.length === 10 ? 'MM/DD/YYYY' : undefined;
        const m = ignoreTz
            ? moment.utc(value)
            : moment(value, parseExact);
        if (!m.isValid()) return '';
        return m.format(format);
    } 
}
