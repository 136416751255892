export class TitleCaseValueConverter {
    constructor() {}

    toView(value) {
        try {
            if (!value) return value;
            return value.split(' ')
                .map(w => {
                    if (!w) return w;
                    if (w.length === 1) return w[0].toUpperCase();
                    return w[0].toUpperCase() + w.substr(1).toLowerCase();
                })
                .join(' ');
        } catch (err) {
            console.log(err);
            return value;
        }
    }
}
