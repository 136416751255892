export class MaxLengthValueConverter {
    constructor() {}

    toView(value, length = 25) {
        if (!value) return value;
        if (isNaN(length)) return value;
        if (typeof value !== 'string') return value;
        if (value.length <= length) return value;
        return `${value.substring(0, length)}...`;
    }
}
