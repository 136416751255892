import { c } from 'common/common';

export class FormatPercentValueConverter {
    constructor() {}

    toView(value, isDecimal = true, decimals = 0, displayPlusMinus = false, plusMinusZeroValue = '') {
        try {
            if (value === undefined || value === null) return '';
            if (isDecimal) value = value * 100;
            let formattedValue = c.Helpers.formatNumber(value, decimals, true);
            let plusMinus = '';
            if (displayPlusMinus) {
                if (value < 0) plusMinus = formattedValue.indexOf('-') !== 0 ? '-' : '';
                else if (value > 0) plusMinus = '+';
                else plusMinus = plusMinusZeroValue;
            }
            return `${plusMinus}${formattedValue}%`;
        } catch (err) {
            console.log(err);
            return value;
        }
    } 
}
