import { I18n } from 'common/i18n';

export class FormatSecondsValueConverter {
    static inject = [I18n];

    constructor(i18n) {
        this._i18n = i18n;
    }

    toView(value) {
        try {
            if (value < 60) return this._i18n.tr('seconds-count', { count: value });
            const minutes = Math.floor(value / 60);
            const seconds = value - minutes * 60;
            return `${this._i18n.tr('minutes-count', { count: minutes })} ${this._i18n.tr('seconds-count', { count: seconds })}`;
        } catch (err) {
            console.log(err);
            return `${value} seconds`;
        }
    }
}
