import { I18n } from 'common/i18n';

export class LevelNameValueConverter {
    static inject = [I18n];
    _i18n;
    constructor(i18n) {
        this._i18n = i18n;
    }

    toView(value, dropLevelNumber = false) {
        if (!value) return value;
        let ln = this._i18n.tr(`level-${value}`);
        try {
            if (!dropLevelNumber) return ln;
            const parenIndex = ln.indexOf('(');
            if (parenIndex < 0) return ln;
            return ln.substr(0, parenIndex);
        } catch (err) {
            console.log(err);
            return ln;
        }
    }
}
